import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    rwbahasa: {
      id: '',
      bahasa: '',
      kemampuan: '',
      jenis: '',
      userId: null,
      NCSISTIME: null
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rwbahasa = value
    },
  }
})
