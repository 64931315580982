<template>
  <div>
    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div>
      <v-data-table
        :headers="headers"
        :items="rwbahasas"
        :items-per-page="-1"
        :mobile-breakpoint="0"
        :loading="ldata"
        hide-default-footer
        style="text-overflow: ellipsis; white-space: nowrap"
      >
        <template v-slot:top>
          <rwbahasaModalEdit />
          <rwbahasaModalHapus />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-1" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwbahasaModalEdit from "@/components/asn/profil/rwbahasa/ModalEdit";
import rwbahasaModalHapus from "@/components/asn/profil/rwbahasa/ModalHapus";

import modal_editState from "@/store/asn/rwbahasa/modal_edit";
import modal_hapusState from "@/store/asn/rwbahasa/modal_hapus";
import refreshView from "@/store/asn/rwbahasa/viewRwbahasa";

export default {
  name: "rwbahasa",

  components: {
    rwbahasaModalEdit,
    rwbahasaModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    rwbahasas: [],
    get_nip: "",

    ldata: true,

    //Kebutuhan Edit
    editedIndex: -1,
    hapusIndex: -1,

    headers: [
      { text: "Bahasa Yang Dikuasai", align: "start", value: "bahasa" },
      { text: "Kemampuan", value: "kemampuan" },
      { text: "Jenis", value: "jenis" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "80px",
      },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_ASN + "bahasa/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ldata = false;
          refreshView.commit("refreshData", false);
          this.rwbahasas = response.data.mapData.RwBahasa;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.rwbahasas.indexOf(item);
      this.rwbahasa = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
    },

    deleteItem(item) {
      this.hapusIndex = this.rwbahasas.indexOf(item);
      this.rwbahasa = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },
  },
};
</script>
