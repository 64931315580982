<template>
  <div>
    <v-card outlined flat>
      <v-app-bar color="primary" short dense dark flat>
        <v-toolbar-title>Bahasa yang dikuasai</v-toolbar-title>
        <v-spacer></v-spacer>

        <div class="mr-3 mt-1">
          <rwbahasaModalAdd />
        </div>

        <div style="max-width: 300px;">
          <v-tabs v-model="tab" right dark background-color="primary">
            <v-tab href="#tab-1" @click="getSIMASN()">
              <h5>IDASN</h5>
            </v-tab>

            <!-- <v-tab href="#tab-2" @click="getSAPK()">
              <h5>SAPK</h5>
            </v-tab> -->
          </v-tabs>
        </div>
      </v-app-bar>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1" class="pa-5">
          <rwbahasaSIMASN />
        </v-tab-item>
        <!-- <v-tab-item value="tab-2" class="pa-5">
          <rwbahasaSAPK />
        </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import getData from '@/store/asn/rwbahasa/tabs'
import rwbahasaSIMASN from '@/components/asn/profil/rwbahasa/rwbahasa'
// import rwbahasaSAPK from '@/components/asn/profil/rwbahasa/SAPK_rwbahasa_Modal'
import rwbahasaModalAdd from '@/components/asn/profil/rwbahasa/ModalAdd'

export default {
  name: 'DataUtama',

  components: {
    rwbahasaSIMASN,
    // rwbahasaSAPK,
    rwbahasaModalAdd
  },

  computed: {
    dataSAPK: {
      get() {
        return getData.state.tabSAPK
      },

      set(value) {
        getData.commit('getSAPK', value)
      }
    }
  },

  data: () => ({
    tab: null
  }),

  methods: {
    getSAPK() {
      getData.commit('getSAPK', true)
      getData.commit('getSIMASN', false)
    },
    getSIMASN() {
      getData.commit('getSIMASN', true)
      getData.commit('getSAPK', false)
    }
  }
}
</script>
